import React from 'react';
import styled from 'styled-components'
import Layout from '../components/Layout'

const StyledCGV = styled.section`
    margin-top:96px;

    article{
        margin-top:96px;

        h2{
            font-size:1.2rem;
            letter-spacing: 4px;
            font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
        }

        p{
            margin-top:48px;
        }
    }
`

const CGV = () => {
    return (
        <Layout>
            <h1>Conditions générales de vente</h1>
            <StyledCGV>
                <article>
                    <h2>Introduction</h2>
                    <p>Le terme “Prestataire” désigne Marylise Barthélémy, web designer.</p>
                    <p>Le terme “Client(s)” désigne toute personne morale ou physique, ayant requis les compétences de Marylise Barthélémy pour toute création dans le cadre des compétences du web design et du graphisme. Le terme “Tiers” désigne toute personne physique ou morale non partie au contrat.</p>
                    <p>Le terme “Prestation(s)” désigne toutes les prestations pouvant être effectuées par Marylise Barthélémy à savoir : conseils et créations en web design et graphisme (conception et réalisation de sites web, identité visuelle, mise en page, conception de supports print et web, etc.).</p>
                    <p>Les présentes conditions générales de vente ont pour objet de définir les droits et obligations des parties lors de la vente de services réalisés par la Prestataire pour ses Clients dans le cadre de son activité commerciale de création et de conception graphique et web. La Prestataire se réserve le droit de modifier ses conditions générales de vente, ses formules et ses tarifs à tout moment et sans préavis. Ces modifications n’auront aucune incidence sur les commandes en cours.</p>
                    <p>La signature du devis par le Client implique son acceptation des présentes conditions générales de ventes</p>
               
                </article>
                <article>
                    <h2>Généralités</h2>
                    <p>Le Client faisant appel aux services de Marylise Barthélémy, affiliée à la SAS APPUY créateurs (coopérative d'activité et d'emploi), SIRET n° 480 038 546 00028, domiciliée au 1 Avenue des Cottages 63000 CLERMONT FERRAND, qu’il soit un professionnel ou un particulier, reconnaît avoir pris connaissance et accepté sans réserve les conditions générales de vente suivantes (qu’il peut consulter simplement, librement et à tout moment en ligne ou fournies avec devis), ainsi que les mises en garde énoncées dans l’Extrait de la loi n° 57-298 du 11 mars 1957 sur la propriété artistique (J.O. du 14 mars 1957) des droits des auteurs concernant les lois de la propriété intellectuelle.</p>
                    <p>Si le Client est un particulier, il reconnaît être majeur conformément aux lois du pays où il réside. </p>
                    <p>Dans le cadre de son activité, la Prestataire se réserve le droit de refuser la création, le développement, l’intégration et la mise en ligne de tout contenu raciste, sexiste, homophobe, xénophobe, discriminatoire, diffamant, insultant, ou extrémiste et, plus généralement, de toute donnée allant à l’encontre des lois en vigueur sur le territoire Français.</p>
                </article>
                <article>
                    <h2>Confidentialité</h2>
                    <p>Pendant toute la durée des prestations présentes et même après leur cessation pour quelque cause que ce soit, la prestataire s’engage à conserver strictement confidentielles l’ensemble des informations et documents relatifs au Client, de quelle que nature que ce soit, auxquelles elle aurait pu avoir accès dans le cadre notamment du bon de commande, de l’exécution de la présente mission ou de la mise en ligne de son site internet (informations et codes d’hébergement du client).</p>
                    <p>Toutefois, la responsabilité de la Prestataire ne peut être engagée si certaines informations ont été interceptées ou détournées lors du transfert des données. </p>
                </article>
                <article>
                    <h2>Bon déroulement de la mission</h2>
                    <p>La Prestataire s'engage à livrer les fichiers au Client à la date de livraison indiquée dans le cahier des charges et à informer de manière régulière et efficace le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client.</p>
                    <p>Toutefois si le Client fournit des informations erronées ou non complètes, empêchant la réalisation de la mission, la responsabilité de la Prestataire ne peut être engagée par le client qui ne serait pas satisfait de son travail. </p>
                    <p>Le Client s'engage également à être impliqué et réactif afin de permettre le bon déroulement du projet. Tout délai d’approbation trop long entraînera une révision de la date de livraison. La Prestataire ne sera en aucun cas tenue responsable de délais occasionnés par les processus de supervision et d’approbation du client.</p>
                </article>
                <article>
                    <h2>Contenus</h2>
                    <p>Le Client assure être titulaire des droits de propriété intellectuelle nécessaires portant sur l’ensemble des éléments (notamment textes, photos, logos) transmis à la Prestataire dans le cadre de sa mission, et garantit la Prestataire contre toute plainte tierce relative à la violation des droits de ses éléments. </p>
                </article>
                <article>
                    <h2>Sauvegarde et perte de données</h2>
                    <p>Dans le cadre des travaux de graphisme/web design, la Prestataire s’engage à conserver des copies de sauvegarde des fichiers de projet du client, jusqu’à 3 mois après la livraison et conseille au client de faire de même sur son propre ordinateur ou sur tout autre support qu’il jugera bon d’utiliser.</p>
                    <p>Une fois la prestation terminée et livrée au client, la Prestataire ne pourra être tenue pour responsable d’une perte de données intervenant sur le serveur du client ou sur tout autre support que l’ordinateur sur lequel le projet a été développé.</p>
                    <p>La responsabilité de la Prestataire ne saurait en aucun cas être engagée en cas d’intrusion malveillante de tiers sur le site internet du Client, dans ces boîtes aux lettres électroniques, en cas de vol de codes confidentiels ou de mots de passe, de contamination par un virus des données et des logiciels du Client, de dommages causés aux équipements du Client. </p>
                    <p>Les prestations étant complètement indépendantes du fonctionnement des opérateurs de réseaux et des fournisseurs internet, la responsabilité de la Prestataire ne saurait être engagée lors d’une quelconque défaillance.</p>
                    <p>Dans le cas d’une destruction de l’ordinateur de la Prestataire indépendante de sa volonté (virus agressif, incendie, intempéries, foudre, vol…), sa responsabilité dans la perte des données sera entièrement désengagée.</p>
                </article>

            </StyledCGV>
        </Layout>
    );
};

export default CGV;